<template>
  <div class="app-container">
    <div class="outer">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div class="component">
          <h1 class="title">
            1、门店基础信息
          </h1>
          <el-form-item
            label="门店名称："
            prop="name"
          >
            <el-input
              v-model="ruleForm.name"
              class="width360"
              size="mini"
              show-word-limit
              placeholder="请输入门店名称"
            />
          </el-form-item>
          <el-form-item
            label="门店电话："
            prop="moblie"
          >
            <el-input
              v-model="ruleForm.moblie"
              class="width360"
              size="mini"
              show-word-limit
              placeholder="请输入门店电话"
            />
          </el-form-item>
          <el-form-item label="营业时间：">
            <el-table
              :data="tableData"
              border
              size="mini"
              :header-cell-style="{
                background: '#d9dde1',
                color: '#0d0202',
                padding: 0
              }"
            >
              <el-table-column label="时间">
                <template slot-scope="scope">
                  <el-time-picker
                    v-model="scope.row.times"
                    is-range
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    size="mini"
                    value-format="HH:mm:ss"
                  />
                </template>
              </el-table-column>
              <el-table-column label="日期">
                <template slot-scope="scope">
                  <el-cascader
                    v-model="scope.row.days"
                    class="width420"
                    :options="optionsDays"
                    :props="{
                      multiple: true,
                      checkStrictly: true,
                      emitPath: false
                    }"
                    clearable
                    size="mini"
                  />
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                width="70"
              >
                <template slot-scope="scope">
                  <el-link
                    type="danger"
                    @click="deleteDate(scope.row, scope.$index)"
                  >
                    删除
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
            <div>
              <el-button
                type="primary"
                size="mini"
                @click="addDeta"
              >
                添加一条
              </el-button>
            </div>
          </el-form-item>
          <el-form-item
            label="停止售票时间："
            prop="stopSaleTime"
          >
            <el-time-picker
              v-model="ruleForm.stopSaleTime"
              format="HH:mm"
              placeholder="选择时间"
              value-format="HH:mm"
              size="mini"
            />
            <div>将作为景点/采摘日票商品中该门店的每日停止售票时间。</div>
          </el-form-item>
        </div>
        <div class="component">
          <h1 class="title">
            2、自提地点位置信息
          </h1>

          <MapModel @getMapData="getMapData" />

          <el-form-item
            required
            label="门店位置："
            prop="address"
          >
            <el-input
              v-model="ruleForm.address"
              class="width360"
              size="mini"
              show-word-limit
              placeholder="请输入门店位置"
            />
          </el-form-item>
          <el-form-item
            required
            label="自提经纬度："
          >
            <el-form-item
              prop="longitude"
              class="margin_bottom_10"
            >
              <el-input
                v-model="ruleForm.longitude"
                class="width140 margin_right_10"
                size="mini"
                show-word-limit
                placeholder="请在地图中选择地址"
                disabled
                @blur="blurInput(ruleForm.longitude,'longitude')"
              />
              <!-- 例：116.397451 -->
            </el-form-item>
            <el-form-item
              prop="latitude"
              class="margin_bottom_10"
            >
              <el-input
                v-model="ruleForm.latitude"
                class="width140 margin_right_10"
                size="mini"
                show-word-limit
                placeholder="请在地图中选择地址"
                disabled
                @blur="blurInput(ruleForm.latitude,'latitude')"
              />
              <!-- 例：39.909187 -->
            </el-form-item>
          </el-form-item>
        </div>
      </el-form>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
        >
          提 交
        </el-button>
        <el-button
          size="small"
          @click="toBack()"
        >
          返回
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import './style.scss';
import MapModel from '@/components/common/mapModel.vue';

export default {
  components: {
    MapModel,
  },
  data() {
    return {
      query: {},
      ruleForm: {
        name: '',
        moblie: '',
        address: '',
        longitude: '',
        latitude: '',
        stopSaleTime: '23:59',
      },
      rules: {
        name: [
          { required: true, message: '请输入门店名称', trigger: 'change' },
        ],
        moblie: [
          { required: true, message: '请输入门店电话', trigger: 'change' },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: '请输入合法手机号/电话号', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入门店位置', trigger: 'change' },
        ],
        longitude: [
          { required: true, message: '请输入门店经度', trigger: 'change' },
        ],
        latitude: [
          { required: true, message: '请输入门店纬度', trigger: 'change' },
        ],
      },

      tableData: [],
      optionsDays: [
        {
          value: '周一',
          label: '周一',
        },
        {
          value: '周二',
          label: '周二',
        },
        {
          value: '周三',
          label: '周三',
        },
        {
          value: '周四',
          label: '周四',
        },
        {
          value: '周五',
          label: '周五',
        },
        {
          value: '周六',
          label: '周六',
        },
        {
          value: '周日',
          label: '周日',
        },
      ],
    };
  },
  mounted() {
    this.query = this.$route.query;
    if (this.query.type === 'edit') {
      this.getData();
    }
  },
  methods: {
    getData() {
      let offlineStoreInfo = sessionStorage.getItem('offlineStoreInfo');
      if (offlineStoreInfo) {
        offlineStoreInfo = JSON.parse(offlineStoreInfo);
        this.ruleForm = {
          ...offlineStoreInfo,
        };
        this.tableData = JSON.parse(offlineStoreInfo.businessHours).map(({ times, days }) => {
          return {
            times: times.split('-').map((value) => (value.length == 5 ? `${value}:00` : value)),
            days: days.split(','),
          };
        });
      }
    },
    deleteDate(row, index) {
      this.tableData.splice(index, 1);
    },
    addDeta() {
      this.tableData.push({
        times: ['09:00:00', '18:00:00'],
      });
    },
    getMapData(row) {
      // console.log(row);
      this.ruleForm.address = row.name;
      this.ruleForm.longitude = row.lng;
      this.ruleForm.latitude = row.lat;
    },
    blurInput(e, name) {
      if (!e) {
        return;
      }
      let str = '';
      e.split('').forEach((item) => {
        if (item === '.' || item >= 0) {
          str += (item.toString());
        }
      });
      this.ruleForm[name] = str;
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.tableData.length === 0) {
            this.$message({ type: 'error', message: '请添加营业时间' });
            return;
          }
          for (let item of this.tableData) {
            if (!item.days || (item.days && item.days.length === 0) || !item.times) {
              this.$message({ type: 'error', message: '请完善营业时间' });
              return;
            }
          }
          let mylongitude = this.ruleForm.longitude;
          let mylatitude = this.ruleForm.latitude;
          if (!mylongitude || !(mylongitude.toString().split('.')[1])) {
            this.$message({ type: 'error', message: '经纬度格式错误' });
            return;
          }
          if (!mylatitude || !(mylatitude.toString().split('.')[1])) {
            this.$message({ type: 'error', message: '经纬度格式错误' });
            return;
          }

          this.$confirm('是否确认提交?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              let businessHours = this.tableData.map(({ times, days }) => {
                return {
                  times: times.map((value) => value.slice(0, 5)).join('-'),
                  days: days.join(','),
                };
              });
              let params = {
                ...this.ruleForm,
                businessHours: JSON.stringify(businessHours),
                storeId: this.$route.query.storeId ? this.$route.query.storeId : '',
                supplierId: this.$route.query.suppliersId ? this.$route.query.suppliersId : '',
              };
              let apiurl = this.$api.tradeShop.save;
              if (this.query.type === 'edit') {
                apiurl = this.$api.tradeShop.edit;
              }
              this.$axios.post(apiurl, params).then((res) => {
                if (res.code === 0) {
                  this.$message({ type: 'success', message: '操作成功！' });
                  this.$router.push({
                    path: '/nb/offlineStore/list',
                  });
                }
              });
            })
            .catch(() => {});
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    toBack() {
      if (this.query.type === 'show') {
        this.$router.go(-1);
        return;
      }
      this.$confirm('确定返回列表吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.el-form-item {
  margin-bottom: 20px !important;
}
</style>
